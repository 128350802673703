import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import config from "../../../content/meta/config";

const Seo = props => {
  const { data, facebook } = props;
  const postTitle = ((data || {}).frontmatter || {}).title;
  const postDescription = ((data || {}).frontmatter || {}).description;
  const postCover = ((data || {}).frontmatter || {}).cover;
  let postSlug = ((data || {}).fields || {}).slug ;

  let keywords = ((data || {}).frontmatter || {}).tags;

  const title = postTitle ? `${postTitle} - ${config.shortSiteTitle}` : config.siteTitle;
  const description = postDescription ? postDescription : config.siteDescription;
  const image = postCover ? postCover : config.siteImage;

  let keywordValues = "" ;
  if(keywords){
    const length = keywords.length ;
    for(let i=0; i<length ;i++){
      keywordValues = keywordValues + keywords[i] + " ,"  ;
    }
    keywordValues = keywordValues + "" + keywords[length-1] ;
  }
  else{
    keywordValues = "Ultra Legends Club, Invest Like a Legend, ultralegendsclub, Real Estate Investment in India, Best cities to invest in india,Rent Savings Calculator, All about Investing in India, Why Bangalore is Best place to invest, Good Debt, Money making money"
  }

  if(!postSlug){
    postSlug = "" ;
  }

  const url = config.siteUrl + config.pathPrefix + postSlug;

  return (
    <Helmet
      htmlAttributes={{
        lang: config.siteLanguage,
        prefix: "og: http://ogp.me/ns#"
      }}
    >
      {/* General tags */}
      <title>{title}</title>
      <meta name="description" content={description} />

      <meta name="keywords" content={keywordValues}/>
      {/* OpenGraph tags */}
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:type" content="website" />
      <meta property="fb:app_id" content={facebook.appId} />
      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary" />
      <meta
        name="twitter:creator"
        content={config.authorTwitterAccount ? config.authorTwitterAccount : ""}
      />
    </Helmet>
  );
};

Seo.propTypes = {
  data: PropTypes.object,
  facebook: PropTypes.object.isRequired
};

export default Seo;
